import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Allegro.css";

function Allegro() {
    return (
        <>
            <Row>
                <Col xs ={12} className = "xd">
                    <h1 class="section-heading text-uppercase"> Los beneficios de Allegro</h1>
                </Col>
            </Row>
            <Container>

                <Row>
                    <Col xs={6}>
                        <img
                            class="imagen img-fluid"
                            src="img/guitar1.jpeg"
                            alt=""
                        />
                    </Col>
                    <Col xs={6} className="vcenter">
                        <div class="timeline-header">Mejora tu postura</div>
                        <div class="timeline-body">
                            <p class="text-muted">
                                Por medio de la inteligencia artificial, Allegro
                                identificara tus problemas al tocar y te
                                indicara como mejorar.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="vcenter">
                        <div class="timeline-header timeline-left">
                            Mejora tu entonación
                        </div>
                        <div class="timeline-body">
                            <p class="text-muted">
                                Allegro te escuchara e interpretara el nivel de
                                entonación dentro que las notas que tocas con tu
                                violin.
                            </p>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <img
                            class="imagen img-fluid"
                            src="img/violin.jpeg"
                            alt=""
                        />
                    </Col>
                </Row>
                <Row className="evo">
                    <Col xs={6}>
                        <img
                            class="imagen img-fluid"
                            src="img/metronome.jpg"
                            alt=""
                        />
                    </Col>
                    <Col xs={6} className="vcenter">
                        <div class="timeline-header">Mejora tu ritmo</div>
                        <div class="timeline-body">
                            <p class="text-muted">
                                Practica con tu instrumento junto a Allegro para
                                medir tu capacidad de tocar al beat de tu
                                música.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Allegro;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./Navbar.css";

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener("resize", showButton);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link
                        to="/"
                        className="navbar-logo"
                        onClick={closeMobileMenu}
                    >
                        <img
                            width="70%"
                            height="70%"
                            src="img/wing2.svg"
                            alt=""
                        />
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? "fas fa-times" : "fas fa-bars"} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <Link
                                to="/Allegro"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                Allegro Training
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link
                                to="/Noticias"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                Noticias
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link
                                to="/Equipo"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                Equipo
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;

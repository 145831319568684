import React from "react";
import "../../App.css";
//import Cards from '../Cards';
import Team from "../Team";
import Footer from "../Footer";

function Equipo() {
    return (
        <>
            <Team />
            <Footer />
        </>
    );
}

export default Equipo;

import React, { useState } from "react";
import { Button } from "./Button";
import "./HeroSection.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function HeroSection() {
    /*
    const [place, setPlace] = useState("COMIENZA A PRACTICAR");
    const handleClick = () => setPlace("Proximamente!");
    <div className="hero-btns">
        <Button className="outline" onClick={handleClick}>
            {place}
        </Button>
        <Button
            className="primary"
            link="https://www.youtube.com/watch?v=CNhuNi7jLt0"
        >
            MIRA EL TRAILER <i className="far fa-play-circle" />
        </Button>
    </div>
    */
    return (
        <div>
            <div className="hero-container">
                <video src="/videos/guitarvid_1.mp4" autoPlay loop muted />

                <div className="hero-child">
                    <img className = "white-icon-allegro" src="img/allegrowhite.svg" alt=""/>
                    
                    <p style={{fontSize:"big"}}>Alcanza el nivel que siempre soñaste</p>

                    <a href="https://allegro-training.herokuapp.com/">
                            <button type="button" class="btn btn-outline-light allegro-button" hrlink>
                                ¡COMIENZA A PRACTICAR!
                            </button>
                    </a>
                </div>


                <div className="semi">           
                    <div class="bellow text-center datenshi">
                        <h1 class="eva section-heading text-uppercase">
                            Descubre una nueva forma de practicar
                        </h1>
                        <h5 class="section-subheading text-muted">
                            Tu nuevo asistente de practica inteligente
                        </h5>
                    </div>
                </div>
             </div>
             <div className="row chamullo">
                    <div className="col-6 vcenter">
                        <p className = "huh">Con Allegro Training podrás practicar aspectos críticos para llevar tu técnica al siguiente nivel. </p>
                    </div>
                    <div className="col-6 fra">
                        <iframe width="65%" height="90%" src="https://www.youtube-nocookie.com/embed/Qd3RmVLrAQg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
            </div>
        </div>

    );
}

export default HeroSection;

import React from "react";
import "../App.css";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Team.css";

function Team() {
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12} className="teamhead eva2 text-center">
                        <h2 className="encabe section-heading text-uppercase big">
                            Nuestro Gran Equipo
                        </h2>
                        <h5 className="evo section-subheading text-muted">
                            Somos un equipo unido, extravagante y altamente
                            motivado
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className="team-member">
                            <img
                                className="mx-auto rounded-circle d-block m-auto"
                                src="img/seba.jpg"
                                alt=""
                            />
                            <h4 className="text-center">Sebastián Alvarado</h4>
                            <p className="text-muted text-center">
                                Product Owner
                            </p>
                            <ul className="list-inline social-buttons text-center">
                                <li className="list-inline-item">
                                    <a href="https://www.linkedin.com/in/sebasti%C3%A1n-alvarado-aceituno-581a821a8/">
                                        <i className="fa fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="team-member">
                            <img
                                className="mx-auto rounded-circle d-block m-auto"
                                src="img/fabian.jpg"
                                alt=""
                            />
                            <h4 className="text-center">Fabian Riquelme</h4>
                            <p className="text-muted text-center">
                                Lead Developer
                            </p>
                            <ul className="list-inline social-buttons text-center">
                                <li className="list-inline-item">
                                    <a href="https://www.linkedin.com/in/fabi%C3%A1n-marcelo-riquelme-mac%C3%ADas-53b405140/">
                                        <i className="fa fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className="team-member">
                            <img
                                className="mx-auto rounded-circle d-block m-auto"
                                src="img/felipe.jpg"
                                alt=""
                            />
                            <h4 className="text-center">Felipe Olavarría</h4>
                            <p className="text-muted text-center">
                                Front-End Developer
                            </p>
                            <ul className="list-inline social-buttons text-center">
                                <li className="list-inline-item">
                                    <a href="https://www.linkedin.com/in/felipe-ignacio-olavarr%C3%ADa-riquelme-4050371a6/">
                                        <i className="fa fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="team-member">
                            <img
                                className="mx-auto rounded-circle d-block m-auto"
                                src="img/luciano.jpg"
                                alt=""
                            />
                            <h4 className="text-center">Luciano Martel</h4>
                            <p className="text-muted text-center">
                                Back-End Developer
                            </p>
                            <ul className="list-inline social-buttons text-center">
                                <li className="list-inline-item">
                                    <a href="https://www.linkedin.com/in/luciano-ignacio-0228221a7/">
                                        <i className="fa fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8} className="mx-auto text-center">
                        <p className="large text-muted">
                            Estudiantes de Ingeniería Civil en Informática de la
                            prestigiosa Universidad Técnica Federico Santa
                            María.
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Team;

import React from "react";
import "../../App.css";
import Error from "../Error";
import Footer from "../Footer";

function News() {
    return (
        <>
            <Error />
            <Footer />
        </>
    );
}

export default News;

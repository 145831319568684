import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";
import "./Wings.css";

function Wings() {
    return (
        <>
            <div className="hero-container">
                <video src="/videos/violin2.mp4" autoPlay loop muted />
                <h1>Viste tu mejor par de alas</h1>
                <p>Let music fly</p>
                <div className="wut"></div>
            </div>
            
            <Container style={{backgroundColor:"white"}}>
                <Row className="EVAwi evo">
                    <Col md={6} className="vcenter">
                        <div class="timeline-header">
                            <img class="allegro-icon" src="img/allegro.svg" alt="" />
                        </div>

                        <div class="timeline-body">
                            <p class="text-muted allegro-text">
                                Allegro Training es una aplicación pensada para
                                practicantes de guitarra y violín, ofreciendo
                                varias herramientas útiles para una práctica
                                efectiva.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <img
                            class="imagen img-fluid"
                            src="img/guitar2.jpg"
                            alt=""
                        />
                    </Col>
                </Row>
            </Container>
            <div className="villian-container eva2">
                <h2>
                    Nuestra misión es facilitar el aprendizaje de miles de personas usando los mejores avances tecnológicos
                </h2>
                <p>
                    Por medio de redes neuronales, el dispositivo observa y
                    escucha lo que está tocando en tiempo real dando
                    retroalimentación.
                </p>
                <p>
                    Para brindar la mejor experiencia agregamos todas las
                    herramientas que necesites durante tu practica. Metronomo,
                    afinador, librerias musicales entre otras. Todas las
                    herramientas que necesites con un acceso fácil.
                </p>
            </div>
        </>
    );
}

export default Wings;

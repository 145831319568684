import React from "react";
import "../../App.css";
import Wings from "../Wings";
import Footer from "../Footer";

function Home() {
    return (
        <>
            <Wings />
            <Footer />
        </>
    );
}

export default Home;

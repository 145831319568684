import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import AllegroTraining from "./components/pages/AllegroTraining";
import Equipo from "./components/pages/Equipo";
import PageNotFound from "./components/pages/PageNotFound";
// import News from "./components/pages/News";
import "./App.css";

function App() {
    return (
        <>
            <Router>
                <Navbar />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/Allegro" exact component={AllegroTraining} />
                    {/* <Route path="/Noticias" exact component={News} /> */}
                    <Route path="/Equipo" exact component={Equipo} />
                    <Route path='*' exact={true} component={PageNotFound} />
                </Switch>
            </Router>
        </>
    );
}

export default App;

import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import Footer from "../Footer";
import Allegro from "../Allegro";

function Home() {
    return (
        <>
            <HeroSection />
            <Allegro />
            <Footer />
        </>
    );
}

export default Home;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";

function Feed() {
    return (
        <>        
           <Container>
               <div className="notFound">
                    <img class="allegro-icon" src="img/404.png" alt="" />
               </div>
           </Container>
        </>

    );
}

export default Feed;